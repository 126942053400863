import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="When and How You'll Be Paid">
    <Hero title="When and How You'll Be Paid" />
    <Container size="medium">
      <Stack spacing="XLarge">
        <EmberCard title="Introduction">
          <EmberCardSection>
            <Stack>
              <Text>
                You'll be paid on Tuesday each week by bank transfer. This
                payment will cover the prior Monday - Sunday. It's important to
                make sure we have your latest bank details, otherwise your pay
                will go to the wrong place. Contact the Operations Team in your
                room if something changes and they can arrange for it to be
                updated.
              </Text>
              <Text>
                Around the same time you'll be sent a payslip by email. You'll
                need a password to open the attached PDF but the email explains
                what the password will be – it's based on your name and date of
                birth.
              </Text>
              <Text>
                If you have questions or anything on your payslip looks wrong
                (e.g. if your address has changed or you think your pay is
                incorrect), then contact the Operations Team in your channel and
                they can get it sorted.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard title="Understanding Your Payslip">
          <EmberCardSection>
            <Text>
              Your payslip will look something like the example below. We've
              added a few notes to help you understand what each section means
              but please ask the Operations Team in your channel if you need any
              further help understanding your payslip.
            </Text>
          </EmberCardSection>
          <EmberCardSection>
            <StaticImage
              alt="Example payslip"
              src="./images/payslip-example.png"
            />
          </EmberCardSection>
        </EmberCard>
      </Stack>
    </Container>
  </DriveLayout>
)

export default Page
